<template>
  <v-row class="signup-inv ma-0">
    <v-col>
      <v-card elevation="5" max-width="1070" class="mx-auto d-flex">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pa-lg-10 pa-md-5 pt-5 ma-auto d-sm-block d-none">
            <v-img class="rounded-l ma-auto img" cover src="@/assets/img/auth.svg"></v-img>
          </v-col>
          <v-col cols="12" md="6" class="pa-lg-10 pa-sm-10 pa-5 pt-sm-4">
            <v-card-title class="pa-0 justify-center flex-column mb-3">
              <div class="font-weight-bold text-h5">WELCOME TO</div>
              <div class="opasity--text">Company {{ name }}</div>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-form @submit.prevent="action" ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" class="py-sm-3 py-0">
                    <v-text-field
                      v-model="firstname"
                      label="First Name"
                      :error-messages="firstnameErrors"
                      filled
                      outlined
                      dense
                      required
                      color="input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-sm-3 pt-0">
                    <v-text-field
                      v-model="lastname"
                      label="Last Name"
                      :error-messages="lastnameErrors"
                      filled
                      outlined
                      dense
                      required
                      color="input"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field class="mt-n1" v-model="email" label="Email" outlined dense readonly color="input"></v-text-field>
                <v-text-field
                  class="mt-n1"
                  v-model="password"
                  label="Password"
                  :error-messages="passwordErrors"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  hint="Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,."
                  persistent-hint
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
                <v-checkbox hide-details class="mt-0" v-model="privacy" color="success">
                  <template v-slot:label>
                    <div class="text-body-1 secondary--text mt-1">
                      Agree to
                      <router-link to="/terms-of-service" class="font-weight-medium secondary--text">Terms of Service</router-link>
                      and <router-link to="/privacy-policy" class="font-weight-medium secondary--text">Privacy policy</router-link>
                    </div>
                  </template>
                </v-checkbox>
                <div class="text-center mt-4">
                  <v-btn :disabled="!privacy" type="submit" max-width="300" width="100%" height="48" color="primary">Sign Up</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import jwt_decode from 'jwt-decode';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    firstname: { required },
    lastname: { required },
    password: { required },
  },
  data() {
    return {
      show: false,
      firstname: null,
      lastname: null,
      password: null,
      privacy: false,
      status: null,
      error: null,
    };
  },
  mounted() {
    if (jwt_decode(this.$route.query.t).exp < Date.now() / 1000) {
      this.$router.push('/expired-invitation');
    }
  },
  computed: {
    email() {
      return jwt_decode(this.$route.query.t).ame;
    },
    name() {
      return jwt_decode(this.$route.query.t).acn;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) {
        return errors;
      }
      !this.$v.firstname.required && errors.push('Please enter your first name');
      this.error == 'first_name__invalid' && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.lastname.$dirty) {
        return errors;
      }
      !this.$v.lastname.required && errors.push('Please enter your last name');
      this.error == 'last_name__invalid' && errors.push('Provided last name is not valid');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      !this.$v.password.required && errors.push('Please enter your password');
      this.error == 'password__invalid' &&
        errors.push('Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,.');
      return errors;
    },
  },
  methods: {
    async action() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.error = null;
        const data = {
          first_name: this.firstname,
          last_name: this.lastname,
          password: this.password,
          invitation_token: this.$route.query.t,
        };
        await this.$store
          .dispatch('signUpInvit', data)
          .then((res) => {
            this.status = res.status;
            sessionStorage.setItem('jwt_token', res.data.access_token);
            setTimeout(() => {
              this.$router.push('/dashboard');
            }, 300);
          })
          .catch((e) => {
            this.status = e.response.status;
            this.error = e.response.data.error[0];
          });
      }
    },
  },
};
</script>

<style>
.signup-inv .img {
  max-width: 490px;
}
@media screen and (max-width: 959px) {
  .signup-inv .img {
    max-width: 270px;
  }
}
</style>
